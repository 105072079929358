import dayjs from "dayjs";

/**
 * @param {time} value
 * @param {string} format
 * @returns {string}
 * format: YYYY-MM-DD HH:mm:ss YYYY-MM-DD YYYY-MM-DD HH:mm 等等
 *
 */
export const dataFormat = (
  value: number | string,
  format: string = "YYYY-MM-DD HH:mm:ss"
): string => {
  if (value === "--") {
    return "--";
  }
  if (!value) {
    value = new Date().getTime();
  }
  return dayjs(value).format(format);
};

export const getNowFullYear = (day: any = "") => {
  const nowDate = day == "" ? new Date() : new Date(day);
  let _year = nowDate.getFullYear();
  return _year;
};

export const getNowFullMonth = (day: any = "") => {
  const nowDate = day == "" ? new Date() : new Date(day);
  let _month = nowDate.getMonth() + 1;
  return _month;
};


